@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');

*{
  padding: 0;
  margin: 0;
}
body{
  font-family:'Rubik', sans-serif ;
}
.flex{
  display: flex;
}

$light:#ffffff;
$gray:#e7e9eb;

.navbar{
  background-color: $light;
  padding: 1rem 2rem;
  border-bottom: 1px solid $gray;
}

.sidebar{
  width: 14rem;
  height: calc(100vh - 52px);
  border-right: 1px solid $gray;
  ul{
    list-style: none;
    padding: 1rem 2rem;
    li{
      margin-bottom: 1rem;
      a{
        text-decoration: none;
        &.active{
          background-color: $gray;
        }

      }
    }
  }

}

.excelFile{
  align-items: center;
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
